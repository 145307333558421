<template>
  <b-modal v-model="modalUpdate" size="xl" no-fade id="update-expense" ref="update-expense" :no-close-on-backdrop="true" hide-footer hide-header hide-backdrop>
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">Editar Gasto</h4>
              </div>
              <div class="col-2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <form class="forms-sample">
              <div class="row">
                <div class="form-group col-lg-6" v-if="!setRecord">
                  <label for="record_id">Expediente</label>
                  <v-select
                    v-model="payload.record_id"
                    :options="records_dropdown"
                    id="record_id"
                    :reduce="r => r.code"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('record_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.record_id[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="amount">Monto</label>
                  <input v-model="payload.amount" id="amount" type="number" class="form-control">
                  <div v-if="errors.hasOwnProperty('amount')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.amount[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="description">Descripción</label>
                <textarea v-model="payload.description" name="description" id="description" cols="30" rows="5" class="form-control"></textarea>
                <div v-if="errors.hasOwnProperty('description')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.description[0]}}</small>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-auto">
                  <button @click.prevent="updateModel" class="btn btn-gradient-info btn-md">Actualizar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js'

export default {
  name: 'UpdateExpense',
  props: {
    payload: {
      type: Object,
      required: true
    },
    setRecord: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      errors: {},
      selected: {},
      dateConfig: {
        altInput: true,
        enableTime: true,
        plugins: [new ConfirmDatePlugin({
          confirmIcon: '<i class="fa fa-check"></i>',
          confirmText: 'OK ',
          showAlways: false
        })],
        altFormat: 'F j, Y G:i K',
        dateFormat: 'Y-m-d H:i',
        time_24hr: false,
        locale: Spanish // locale for this instance only,
      }
    }
  },
  watch: {
    payload: {
      immediate: true,
      deep: true,
      handler (value) {
        const today = this.$moment()
        if (this.$moment(value.date).isBefore(today, 'd')) {
          this.errors.date = ['La fecha es inferior al día de hoy']
        } else {
          this.errors.date = ''
        }
      }
    }
  },
  methods: {
    ...mapMutations('Expense', ['TOGGLE_MODAL_UPDATE']),
    ...mapActions({
      update: 'Expense/update',
      create: 'Expense/create',
      getRecordsDropdown: 'Record/getRecordsDropdown'
    }),
    updateModel () {
      this.update(this.payload)
        .then(() => {
          this.reset()
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    reset () {
      this.errors = {}
      this.TOGGLE_MODAL_UPDATE(false)
      this.$events.fire('refresh-table')
    }
  },
  computed: {
    ...mapGetters({
      modalUpdate: 'Expense/modalUpdate',
      records_dropdown: 'Record/records_dropdown'
    })
  },
  mounted () {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'create-expense' || modalId === 'update-expense') {
        this.form.record_id = this.setRecord
      }
    })
  },
  created () {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'update-expense') {
        this.form.record_id = this.setRecord
      }
    })
  }
}
</script>
