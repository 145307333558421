<template>
  <layout>
    <template slot="content">
      <div>
        <div class="page-header">
          <h3 class="page-title"> Gastos </h3>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"> Gastos </a></li>
              <li class="breadcrumb-item active" aria-current="page">Todos las gastos</li>
            </ol>
          </nav>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row justify-content-end align-items-center mb-4">
                  <div class="col-auto">
                    <button class="btn btn-sm btn-gradient-info" @click.prevent="openModelCreate">
                      <i class="mdi mdi-plus"></i> Nuevo
                    </button>
                  </div>
                </div>
                <datatable :api="apiEndPoint" :fields="fields"></datatable>
              </div>
            </div>
          </div>
        </div>
        <modal-create></modal-create>
        <modal-update v-if="selected" :payload="selected"></modal-update>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/BaseLayout'
import Datatable from '@/components/TableLayout'
import ModalCreate from '@/components/CreateExpense'
import ModalUpdate from '@/components/EditExpense'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'Expenses',
  data () {
    return {
      apiEndPoint: 'api/expenses',
      fields: [
        {
          name: 'expense',
          title: 'Fecha'
        },
        {
          name: 'record',
          title: 'Expediente'
        },
        {
          name: 'amount',
          title: 'Monto'
        },
        {
          name: 'invoiced',
          title: 'Estado',
          formatter: value => {
            const date = this.$moment(value)
            return date.isValid() ? '<div>Facturado</div><small>' + date.format('DD-MM-YYYY') + '</small>' : 'No facturado'
          }
        },
        {
          name: 'expense_receipt_button',
          title: 'Acción'
        }
      ],
      selected: null
    }
  },
  methods: {
    ...mapMutations('Expense', ['TOGGLE_MODAL_CREATE', 'TOGGLE_MODAL_UPDATE']),
    ...mapActions({
      getRecordsDropdown: 'Record/getRecordsDropdown'
    }),
    openModelCreate () {
      this.TOGGLE_MODAL_CREATE()
    },
    openModelUpdate () {
      this.TOGGLE_MODAL_UPDATE()
    }
  },
  mounted () {
    this.getRecordsDropdown()
    this.$events.$on('table-item-edit', eventData => {
      this.selected = eventData
      this.openModelUpdate()
    })
  },
  beforeDestroy () {
    this.$events.off('table-item-edit')
  },
  components: {
    Layout,
    Datatable,
    ModalCreate,
    ModalUpdate
  }
}
</script>
